// extracted by mini-css-extract-plugin
export var column = "TelephoneField__column__FPLq2";
export var disabled = "TelephoneField__disabled__RsO6Z";
export var error = "TelephoneField__error__HfKh5";
export var icon = "TelephoneField__icon__GAzsS";
export var input = "TelephoneField__input__HahUi";
export var marginLeft = "TelephoneField__marginLeft__BkwSj";
export var marginRight = "TelephoneField__marginRight__ItbZW";
export var row = "TelephoneField__row__zD883";
export var sizeLg = "TelephoneField__sizeLg__mng60";
export var sizeMd = "TelephoneField__sizeMd__m9Me1";
export var sizeSm = "TelephoneField__sizeSm__WioKz";
export var sizeXl = "TelephoneField__sizeXl__fgAMF";
export var sizeXs = "TelephoneField__sizeXs__NVd3C";
export var sizeXxl = "TelephoneField__sizeXxl__oZAPC";
export var sizeXxs = "TelephoneField__sizeXxs__TsEEj";
export var sizeXxxl = "TelephoneField__sizeXxxl__oOzjg";
export var sizeXxxs = "TelephoneField__sizeXxxs__XeHGn";
export var sizeXxxxl = "TelephoneField__sizeXxxxl__CMe5u";
export var sizeXxxxxl = "TelephoneField__sizeXxxxxl__MO98C";
export var spinner = "TelephoneField__spinner__btzeW";
export var withRightSideAddon = "TelephoneField__withRightSideAddon__SX91q";
export var wrapper = "TelephoneField__wrapper__gzCEV";