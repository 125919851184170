// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Url from "../../../../../utils/Url.res.js";
import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Uploader from "../../../../../styleguide/forms/Uploader/Uploader.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconUpload from "../../../../../styleguide/icons/IconUpload.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContact from "../../../../../models/UserContact.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as ProjectWizard from "../ProjectWizard/ProjectWizard.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Routes_Project from "../../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ReactNotifications from "react-notifications";
import * as ProjectWizardNavigation from "../ProjectWizardNavigation/ProjectWizardNavigation.res.js";
import * as ProjectWizardGeneralInformationScss from "./ProjectWizardGeneralInformation.scss";
import * as ProjectWizardGeneralInformation_FlagsSection from "./ProjectWizardGeneralInformation_FlagsSection.res.js";
import * as ProjectWizardGeneralInformation_DescribeProjectSection from "./ProjectWizardGeneralInformation_DescribeProjectSection.res.js";
import * as ProjectWizardGeneralInformation_Data_GeneralInformation from "./ProjectWizardGeneralInformation_Data_GeneralInformation.res.js";
import * as ProjectWizardGeneralInformation_DocumentsWithRfpDetails from "./ProjectWizardGeneralInformation_DocumentsWithRfpDetails.res.js";
import * as ProjectWizardGeneralInformation_ContactInformationSection from "./ProjectWizardGeneralInformation_ContactInformationSection.res.js";
import * as ProjectWizardGeneralInformation_BusinessContactInformationSection from "./ProjectWizardGeneralInformation_BusinessContactInformationSection.res.js";

var css = ProjectWizardGeneralInformationScss;

function updateFieldInContact(contact, field, value) {
  if (field === "JobTitle") {
    return {
            id: contact.id,
            fullName: contact.fullName,
            jobTitle: value,
            companyName: contact.companyName,
            image: contact.image,
            email: contact.email,
            phone: contact.phone,
            isPrimary: contact.isPrimary,
            isManager: contact.isManager
          };
  } else if (field === "Email") {
    return {
            id: contact.id,
            fullName: contact.fullName,
            jobTitle: contact.jobTitle,
            companyName: contact.companyName,
            image: contact.image,
            email: value,
            phone: contact.phone,
            isPrimary: contact.isPrimary,
            isManager: contact.isManager
          };
  } else if (field === "CompanyName") {
    return {
            id: contact.id,
            fullName: contact.fullName,
            jobTitle: contact.jobTitle,
            companyName: value,
            image: contact.image,
            email: contact.email,
            phone: contact.phone,
            isPrimary: contact.isPrimary,
            isManager: contact.isManager
          };
  } else if (field === "IsPrimary") {
    return {
            id: contact.id,
            fullName: contact.fullName,
            jobTitle: contact.jobTitle,
            companyName: contact.companyName,
            image: contact.image,
            email: contact.email,
            phone: contact.phone,
            isPrimary: value === "true",
            isManager: contact.isManager
          };
  } else if (field === "FullName") {
    return {
            id: contact.id,
            fullName: value,
            jobTitle: contact.jobTitle,
            companyName: contact.companyName,
            image: contact.image,
            email: contact.email,
            phone: contact.phone,
            isPrimary: contact.isPrimary,
            isManager: contact.isManager
          };
  } else if (field === "IsManager") {
    return {
            id: contact.id,
            fullName: contact.fullName,
            jobTitle: contact.jobTitle,
            companyName: contact.companyName,
            image: contact.image,
            email: contact.email,
            phone: contact.phone,
            isPrimary: contact.isPrimary,
            isManager: value === "true"
          };
  } else {
    return {
            id: contact.id,
            fullName: contact.fullName,
            jobTitle: contact.jobTitle,
            companyName: contact.companyName,
            image: contact.image,
            email: contact.email,
            phone: value,
            isPrimary: contact.isPrimary,
            isManager: contact.isManager
          };
  }
}

function saveDraft(state, api) {
  var generalInfo_title = state.title;
  var generalInfo_description = state.description;
  var generalInfo_isAgent = state.isAgent;
  var generalInfo_businessName = state.businessName;
  var generalInfo_businessContactName = state.businessContactName;
  var generalInfo_headquartersAddress = state.headquartersAddress;
  var generalInfo_businessPhone = state.businessPhone;
  var generalInfo_businessEmail = state.businessEmail;
  var generalInfo_contacts = state.contacts;
  var generalInfo_agency = state.agency;
  var generalInfo = {
    title: generalInfo_title,
    description: generalInfo_description,
    isAgent: generalInfo_isAgent,
    businessName: generalInfo_businessName,
    businessContactName: generalInfo_businessContactName,
    headquartersAddress: generalInfo_headquartersAddress,
    businessPhone: generalInfo_businessPhone,
    businessEmail: generalInfo_businessEmail,
    contacts: generalInfo_contacts,
    agency: generalInfo_agency
  };
  $$Promise.wait(api.saveDraft(generalInfo), (function (x) {
          if (x.TAG === "Ok") {
            "Draft saved!".success(undefined, 1000, ReactNotifications.NotificationManager);
            return ;
          }
          SentryLogger.error1({
                rootModule: "ProjectWizardGeneralInformation",
                subModulePath: {
                  hd: "Api",
                  tl: /* [] */0
                },
                value: "saveDraft",
                fullPath: "ProjectWizardGeneralInformation.Api.saveDraft"
              }, "UpdateDraft::Error", [
                "Error",
                x._0
              ]);
          "Something went wrong trying to save the draft".error(undefined, 1000, ReactNotifications.NotificationManager);
        }));
}

function createOrSaveProject(projectId, state, userRole, api, dispatch, userAction) {
  if (projectId === undefined) {
    return $$Promise.wait(api.createProject(state.title, state.description, state.isAgent, state.agency, state.businessName, state.businessContactName, state.headquartersAddress, state.businessPhone, state.businessEmail, state.contacts, state.documents), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.success(x._0));
                  }
                  SentryLogger.error1({
                        rootModule: "ProjectWizardGeneralInformation",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "createOrSaveProject",
                        fullPath: "ProjectWizardGeneralInformation.Api.createOrSaveProject"
                      }, "CreateNewProject::Error", [
                        "Error",
                        x._0
                      ]);
                  dispatch({
                        TAG: "SetErrorMessage",
                        _0: "Validation failed, please verify"
                      });
                }));
  }
  var tmp;
  if (userAction === "ClickOnSaveAndContinueLater") {
    tmp = "Draft";
  } else {
    var match = state.status;
    switch (match) {
      case "Draft" :
          switch (userRole) {
            case "Provider" :
            case "User" :
            case "Visitor" :
                tmp = "Review";
                break;
            default:
              tmp = "Active";
          }
          break;
      case "Active" :
          tmp = "Active";
          break;
      case "Inactive" :
          tmp = "Inactive";
          break;
      case "Finalized" :
          tmp = "Finalized";
          break;
      case "Review" :
          tmp = "Review";
          break;
      
    }
  }
  $$Promise.wait(api.updateProject(Caml_option.valFromOption(projectId), state.title, state.description, state.isAgent, state.agency, state.businessName, state.businessContactName, state.headquartersAddress, state.businessPhone, state.businessEmail, state.contacts, state.documents, state.deletedDocumentIds, tmp), (function (x) {
          if (x.TAG === "Ok") {
            if (userAction === "ClickOnSaveAndContinueLater") {
              return Url.visit(Routes_Project.index);
            } else {
              return Url.visit(Routes_Project.detail(x._0));
            }
          }
          SentryLogger.error1({
                rootModule: "ProjectWizardGeneralInformation",
                subModulePath: {
                  hd: "Api",
                  tl: /* [] */0
                },
                value: "createOrSaveProject",
                fullPath: "ProjectWizardGeneralInformation.Api.createOrSaveProject"
              }, "UpdateProject::Error", [
                "Error",
                x._0
              ]);
          dispatch({
                TAG: "SetErrorMessage",
                _0: "Validation failed, please verify"
              });
        }));
}

function saveDraftProjectAndContinueLater(api, dispatch) {
  $$Promise.wait(api.saveDraftProjectAndContinueLater("Draft"), (function (x) {
          if (x.TAG === "Ok") {
            return Url.visit(Routes_Project.index);
          }
          SentryLogger.error1({
                rootModule: "ProjectWizardGeneralInformation",
                subModulePath: {
                  hd: "Api",
                  tl: /* [] */0
                },
                value: "saveDraftProjectAndContinueLater",
                fullPath: "ProjectWizardGeneralInformation.Api.saveDraftProjectAndContinueLater"
              }, "saveDraftProjectAndContinueLater::saveProjectDraft::Error", [
                "Error",
                x._0
              ]);
          dispatch({
                TAG: "SetErrorMessage",
                _0: "Validation failed, please verify"
              });
        }));
}

var Api = {
  saveDraft: saveDraft,
  createOrSaveProject: createOrSaveProject,
  saveDraftProjectAndContinueLater: saveDraftProjectAndContinueLater
};

function ProjectWizardGeneralInformation(props) {
  var status = props.status;
  var navigationBackButtonLocation = props.navigationBackButtonLocation;
  var agencyOptions = props.agencyOptions;
  var agency = props.agency;
  var contacts = props.contacts;
  var projectId = props.projectId;
  var userRole = props.userRole;
  var api = props.api;
  var tmp;
  tmp = projectId !== undefined ? props.isAgent : (
      userRole === "Agent" ? true : false
    );
  var match = Belt_Array.get(agencyOptions, 0);
  var initialState_title = props.title;
  var initialState_description = props.description;
  var initialState_documents = [];
  var initialState_deletedDocumentIds = [];
  var initialState_businessName = props.businessName;
  var initialState_businessContactName = props.businessContactName;
  var initialState_businessEmail = props.businessEmail;
  var initialState_businessPhone = props.businessPhone;
  var initialState_headquartersAddress = props.headquartersAddress;
  var initialState_contacts = contacts.length !== 0 ? contacts : [UserContact.createEmpty()];
  var initialState_userLoginStatus = props.userLoginStatus;
  var initialState_agency = agency !== undefined ? (
      match !== undefined && !(function (__x) {
            return Js_array.includes(agency, __x);
          })(agencyOptions) ? match : agency
    ) : (
      match !== undefined ? match : ""
    );
  var initialState = {
    title: initialState_title,
    description: initialState_description,
    documents: initialState_documents,
    deletedDocumentIds: initialState_deletedDocumentIds,
    businessName: initialState_businessName,
    businessContactName: initialState_businessContactName,
    businessEmail: initialState_businessEmail,
    businessPhone: initialState_businessPhone,
    headquartersAddress: initialState_headquartersAddress,
    contacts: initialState_contacts,
    isAgent: tmp,
    userLoginStatus: initialState_userLoginStatus,
    acceptedTermsAndConditions: false,
    signInModal: "Hidden",
    projectStatus: "Creating",
    validationStatus: undefined,
    errorMessage: undefined,
    agency: initialState_agency,
    signInModalFor: "None",
    status: status
  };
  var saveDraft$1 = ReactDebounce.useDebounced(1000, (function (param) {
          if (param[0] !== undefined) {
            return ;
          } else {
            return param[1]("SaveDraft");
          }
        }));
  var reducer = function (state, action) {
    if (typeof action !== "object") {
      switch (action) {
        case "AddContact" :
            var newrecord = Caml_obj.obj_dup(state);
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: (newrecord.contacts = Belt_Array.concat(state.contacts, [UserContact.createEmpty()]), newrecord),
                    _1: (function (param) {
                        saveDraft$1([
                              projectId,
                              param.dispatch
                            ]);
                      })
                  };
        case "SaveDraft" :
            return {
                    TAG: "SideEffects",
                    _0: (function (param) {
                        saveDraft(param.state, api);
                      })
                  };
        case "SaveDraftProjectAndContinueLater" :
            return {
                    TAG: "SideEffects",
                    _0: (function (param) {
                        var dispatch = param.dispatch;
                        if (projectId !== undefined) {
                          return createOrSaveProject(projectId, param.state, userRole, api, dispatch, "ClickOnSaveAndContinueLater");
                        } else {
                          return saveDraftProjectAndContinueLater(api, dispatch);
                        }
                      })
                  };
        
      }
    } else {
      switch (action.TAG) {
        case "UpdateTitle" :
            var newrecord$1 = Caml_obj.obj_dup(state);
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: (newrecord$1.title = action._0, newrecord$1),
                    _1: (function (param) {
                        saveDraft$1([
                              projectId,
                              param.dispatch
                            ]);
                      })
                  };
        case "UpdateDescription" :
            var newrecord$2 = Caml_obj.obj_dup(state);
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: (newrecord$2.description = action._0, newrecord$2),
                    _1: (function (param) {
                        saveDraft$1([
                              projectId,
                              param.dispatch
                            ]);
                      })
                  };
        case "AddDocument" :
            var newrecord$3 = Caml_obj.obj_dup(state);
            return {
                    TAG: "Update",
                    _0: (newrecord$3.documents = Belt_Array.concat(state.documents, [action._0]), newrecord$3)
                  };
        case "RemoveDocument" :
            var $$document = action._0;
            var newrecord$4 = Caml_obj.obj_dup(state);
            return {
                    TAG: "Update",
                    _0: (newrecord$4.documents = Js_array.filter((function (stateDocuments) {
                              return stateDocuments.name !== $$document.name;
                            }), state.documents), newrecord$4)
                  };
        case "UpdateField" :
            var fieldName = action._1;
            var value = action._0;
            if (fieldName === "BusinessContactName") {
              var newrecord$5 = Caml_obj.obj_dup(state);
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: (newrecord$5.businessContactName = value, newrecord$5),
                      _1: (function (param) {
                          saveDraft$1([
                                projectId,
                                param.dispatch
                              ]);
                        })
                    };
            }
            if (fieldName === "BusinessPhone") {
              var newrecord$6 = Caml_obj.obj_dup(state);
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: (newrecord$6.businessPhone = value, newrecord$6),
                      _1: (function (param) {
                          saveDraft$1([
                                projectId,
                                param.dispatch
                              ]);
                        })
                    };
            }
            if (fieldName === "BusinessName") {
              var newrecord$7 = Caml_obj.obj_dup(state);
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: (newrecord$7.businessName = value, newrecord$7),
                      _1: (function (param) {
                          saveDraft$1([
                                projectId,
                                param.dispatch
                              ]);
                        })
                    };
            }
            if (fieldName === "BusinessEmail") {
              var newrecord$8 = Caml_obj.obj_dup(state);
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: (newrecord$8.businessEmail = value, newrecord$8),
                      _1: (function (param) {
                          saveDraft$1([
                                projectId,
                                param.dispatch
                              ]);
                        })
                    };
            }
            var newrecord$9 = Caml_obj.obj_dup(state);
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: (newrecord$9.headquartersAddress = value, newrecord$9),
                    _1: (function (param) {
                        saveDraft$1([
                              projectId,
                              param.dispatch
                            ]);
                      })
                  };
        case "RemoveAlreadUploadedDocument" :
            var newrecord$10 = Caml_obj.obj_dup(state);
            return {
                    TAG: "Update",
                    _0: (newrecord$10.deletedDocumentIds = Belt_Array.concat(state.deletedDocumentIds, [action._0.id]), newrecord$10)
                  };
        case "RemoveContact" :
            var index = action._0;
            var newrecord$11 = Caml_obj.obj_dup(state);
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: (newrecord$11.contacts = Js_array.concat(Js_array.slice(index + 1 | 0, state.contacts.length, state.contacts), Js_array.slice(0, index, state.contacts)), newrecord$11),
                    _1: (function (param) {
                        saveDraft$1([
                              projectId,
                              param.dispatch
                            ]);
                      })
                  };
        case "UpdateContact" :
            var field = action._1;
            var index$1 = action._0;
            var updatedContact = updateFieldInContact(Belt_Array.getExn(state.contacts, index$1), field, action._2);
            var currentStateContacts = field === "IsPrimary" ? Belt_Array.map(state.contacts, (function (contact) {
                      return {
                              id: contact.id,
                              fullName: contact.fullName,
                              jobTitle: contact.jobTitle,
                              companyName: contact.companyName,
                              image: contact.image,
                              email: contact.email,
                              phone: contact.phone,
                              isPrimary: false,
                              isManager: contact.isManager
                            };
                    })) : state.contacts;
            var newrecord$12 = Caml_obj.obj_dup(state);
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: (newrecord$12.contacts = Js_array.concat(Js_array.concat(Js_array.slice(index$1 + 1 | 0, state.contacts.length, currentStateContacts), [updatedContact]), Js_array.slice(0, index$1, currentStateContacts)), newrecord$12),
                    _1: (function (param) {
                        saveDraft$1([
                              projectId,
                              param.dispatch
                            ]);
                      })
                  };
        case "ToggleSignInModal" :
            var flag = action._0;
            var newrecord$13 = Caml_obj.obj_dup(state);
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: (newrecord$13.signInModal = flag, newrecord$13),
                    _1: (function (param) {
                        if (projectId !== undefined || flag !== "Shown") {
                          return ;
                        } else {
                          return saveDraft(param.state, api);
                        }
                      })
                  };
        case "ToggleLoginStatus" :
            var newrecord$14 = Caml_obj.obj_dup(state);
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: (newrecord$14.userLoginStatus = action._0, newrecord$14),
                    _1: (function (param) {
                        var dispatch = param.dispatch;
                        var match = state.signInModalFor;
                        switch (match) {
                          case "CreateProject" :
                              dispatch({
                                    TAG: "SetProjectStatus",
                                    _0: "Validating"
                                  });
                              return dispatch({
                                          TAG: "ToggleSignInModalFor",
                                          _0: "None"
                                        });
                          case "DraftProject" :
                              dispatch("SaveDraftProjectAndContinueLater");
                              return dispatch({
                                          TAG: "ToggleSignInModalFor",
                                          _0: "None"
                                        });
                          case "None" :
                              return ;
                          
                        }
                      })
                  };
        case "UpdateFlag" :
            var value$1 = action._1;
            if (action._0 === "TermsAndConditions") {
              var newrecord$15 = Caml_obj.obj_dup(state);
              return {
                      TAG: "Update",
                      _0: (newrecord$15.acceptedTermsAndConditions = value$1, newrecord$15)
                    };
            }
            var newrecord$16 = Caml_obj.obj_dup(state);
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: (newrecord$16.isAgent = value$1, newrecord$16),
                    _1: (function (param) {
                        saveDraft$1([
                              projectId,
                              param.dispatch
                            ]);
                      })
                  };
        case "SetProjectStatus" :
            var newrecord$17 = Caml_obj.obj_dup(state);
            return {
                    TAG: "Update",
                    _0: (newrecord$17.projectStatus = action._0, newrecord$17)
                  };
        case "SetValidationResult" :
            var validationStatus = action._0;
            var valid = ProjectWizardGeneralInformation_Data_GeneralInformation.Validate.areAllFieldsValid(validationStatus);
            var newrecord$18 = Caml_obj.obj_dup(state);
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: (newrecord$18.validationStatus = validationStatus, newrecord$18.projectStatus = valid ? "Submitting" : "ValidationFailed", newrecord$18),
                    _1: (function (param) {
                        var state = param.state;
                        if (state.projectStatus === "Submitting") {
                          return createOrSaveProject(projectId, state, userRole, api, param.dispatch, "ClickOnSaveProject");
                        }
                        
                      })
                  };
        case "SetAgency" :
            var newrecord$19 = Caml_obj.obj_dup(state);
            return {
                    TAG: "Update",
                    _0: (newrecord$19.agency = action._0, newrecord$19)
                  };
        case "ToggleSignInModalFor" :
            var newrecord$20 = Caml_obj.obj_dup(state);
            return {
                    TAG: "Update",
                    _0: (newrecord$20.signInModalFor = action._0, newrecord$20)
                  };
        case "SetErrorMessage" :
            var newrecord$21 = Caml_obj.obj_dup(state);
            return {
                    TAG: "Update",
                    _0: (newrecord$21.errorMessage = action._0, newrecord$21)
                  };
        
      }
    }
  };
  var match$1 = Hooks.useReducer(initialState, reducer);
  var dispatch = match$1[1];
  var state = match$1[0];
  React.useEffect((function () {
          var match = state.projectStatus;
          if (match === "Validating") {
            var validationStatus_businessContactFields = ProjectWizardGeneralInformation_Data_GeneralInformation.Validate.businessContactFields(state.businessName, state.businessContactName, state.businessEmail, state.businessPhone, state.headquartersAddress);
            var validationStatus_contacts = Belt_Array.map(state.contacts, ProjectWizardGeneralInformation_Data_GeneralInformation.Validate.contactFields);
            var validationStatus_title = ProjectWizardGeneralInformation_Data_GeneralInformation.Validate.title(state.title);
            var validationStatus_description = ProjectWizardGeneralInformation_Data_GeneralInformation.Validate.description(state.description);
            var validationStatus_termsAndConditions = ProjectWizardGeneralInformation_Data_GeneralInformation.Validate.termsAndConditions(state.acceptedTermsAndConditions, projectId, status);
            var validationStatus = {
              businessContactFields: validationStatus_businessContactFields,
              contacts: validationStatus_contacts,
              title: validationStatus_title,
              description: validationStatus_description,
              termsAndConditions: validationStatus_termsAndConditions
            };
            dispatch({
                  TAG: "SetValidationResult",
                  _0: validationStatus
                });
          }
          
        }), [state.projectStatus]);
  var navigationPreviousControl = React.useMemo((function () {
          return [
                  "Back",
                  (function () {
                      Url.visit(navigationBackButtonLocation);
                    }),
                  "Enabled"
                ];
        }), [projectId]);
  var navigationNextControl = React.useMemo((function () {
          var tmp;
          tmp = projectId !== undefined ? (
              status === "Draft" ? "Save and Activate" : "Save Changes"
            ) : "Create Project";
          return [
                  tmp,
                  (function () {
                      var match = state.userLoginStatus;
                      if (match === "LoggedIn") {
                        return dispatch({
                                    TAG: "SetProjectStatus",
                                    _0: "Validating"
                                  });
                      }
                      dispatch({
                            TAG: "ToggleSignInModal",
                            _0: "Shown"
                          });
                      dispatch({
                            TAG: "ToggleSignInModalFor",
                            _0: "CreateProject"
                          });
                    }),
                  "Enabled"
                ];
        }), [
        projectId,
        state.userLoginStatus
      ]);
  var navigationContinueLaterControl = React.useMemo((function () {
          var match = state.userLoginStatus;
          if (match === "LoggedIn" && status === "Draft") {
            return [
                    "Save and Continue Later",
                    (function () {
                        dispatch("SaveDraftProjectAndContinueLater");
                      })
                  ];
          }
          
        }), [state.userLoginStatus]);
  var convertButtonClass = projectId !== undefined && status !== "Draft" ? "" : "createProject";
  var x = String(props.navigationTotalSteps);
  return JsxRuntime.jsxs(ProjectWizard.Container.make, {
              children: [
                JsxRuntime.jsxs(ProjectWizard.Content.make, {
                      children: [
                        JsxRuntime.jsx(ProjectWizard.H1.make, {
                              children: projectId !== undefined ? "Edit Project" : "Final Step: Submit Your Quote"
                            }),
                        JsxRuntime.jsx(ProjectWizardGeneralInformation_DescribeProjectSection.make, {
                              title: state.title,
                              updateTitle: (function (title) {
                                  dispatch({
                                        TAG: "UpdateTitle",
                                        _0: title
                                      });
                                }),
                              description: state.description,
                              updateDescription: (function (description) {
                                  dispatch({
                                        TAG: "UpdateDescription",
                                        _0: description
                                      });
                                }),
                              descriptionValidationStatus: Belt_Option.map(state.validationStatus, (function (status) {
                                      return status.description;
                                    })),
                              titleValidationStatus: Belt_Option.map(state.validationStatus, (function (status) {
                                      return status.title;
                                    }))
                            }),
                        JsxRuntime.jsx(ProjectWizardGeneralInformation_DocumentsWithRfpDetails.make, {
                              alreadyUploadedDocuments: Belt_Array.keep(props.alreadyUploadedDocuments, (function ($$document) {
                                      return !Js_array.includes($$document.id, state.deletedDocumentIds);
                                    })),
                              documents: state.documents,
                              removeAlreadyUploadedDocument: (function ($$document) {
                                  dispatch({
                                        TAG: "RemoveAlreadUploadedDocument",
                                        _0: $$document
                                      });
                                }),
                              removeDocument: (function ($$document) {
                                  dispatch({
                                        TAG: "RemoveDocument",
                                        _0: $$document
                                      });
                                })
                            }),
                        JsxRuntime.jsx(Uploader.make, {
                              allowed: [
                                "Pdf",
                                "Doc",
                                "Docx",
                                "Csv",
                                "Vsd",
                                "Xls",
                                "Xlsx"
                              ],
                              multiple: true,
                              className: css.uploader,
                              upload: (function (files) {
                                  Belt_Array.map(files, (function (file) {
                                          var match = Uploader.FileType.fromMime(file.type);
                                          switch (match) {
                                            case "Jpg" :
                                            case "Png" :
                                            case "Avi" :
                                            case "Mp4" :
                                            case "Mov" :
                                            case "Wmv" :
                                                return ;
                                            default:
                                              return dispatch({
                                                          TAG: "AddDocument",
                                                          _0: file
                                                        });
                                          }
                                        }));
                                }),
                              setUploadHandler: (function (param) {
                                  
                                }),
                              children: JsxRuntime.jsxs(Button.make, {
                                    size: "SM",
                                    color: "Teal",
                                    buttonClassName: css.uploadDocumentButton,
                                    children: [
                                      JsxRuntime.jsx(IconUpload.make, {
                                            size: "LG",
                                            color: "White"
                                          }),
                                      "Upload Document"
                                    ]
                                  })
                            }),
                        JsxRuntime.jsx(ProjectWizardGeneralInformation_BusinessContactInformationSection.make, {
                              businessName: state.businessName,
                              businessContactName: state.businessContactName,
                              updateField: (function (value, fieldName) {
                                  dispatch({
                                        TAG: "UpdateField",
                                        _0: value,
                                        _1: fieldName
                                      });
                                }),
                              businessEmail: state.businessEmail,
                              businessPhone: state.businessPhone,
                              headquartersAddress: state.headquartersAddress,
                              validationStatus: Belt_Option.map(state.validationStatus, (function (status) {
                                      return status.businessContactFields;
                                    }))
                            }),
                        JsxRuntime.jsx(ProjectWizardGeneralInformation_ContactInformationSection.make, {
                              contacts: state.contacts,
                              addContact: (function (param) {
                                  dispatch("AddContact");
                                }),
                              removeContact: (function (index) {
                                  dispatch({
                                        TAG: "RemoveContact",
                                        _0: index
                                      });
                                }),
                              updateContact: (function (index, field, value) {
                                  dispatch({
                                        TAG: "UpdateContact",
                                        _0: index,
                                        _1: field,
                                        _2: value
                                      });
                                }),
                              validationStatus: Belt_Option.map(state.validationStatus, (function (status) {
                                      return status.contacts;
                                    }))
                            }),
                        JsxRuntime.jsx(ProjectWizardGeneralInformation_FlagsSection.make, {
                              projectId: projectId,
                              isAgent: state.isAgent,
                              acceptedTermsAndConditions: state.acceptedTermsAndConditions,
                              updateFlag: (function (flag, value) {
                                  dispatch({
                                        TAG: "UpdateFlag",
                                        _0: flag,
                                        _1: value
                                      });
                                }),
                              selectedAgency: state.agency,
                              userRole: userRole,
                              setAgency: (function (agency) {
                                  dispatch({
                                        TAG: "SetAgency",
                                        _0: agency
                                      });
                                }),
                              canAgencyBeManaged: props.canAgencyBeManaged,
                              agencyOptions: agencyOptions,
                              termsAndConditionsValidationStatus: Belt_Option.map(state.validationStatus, (function (status) {
                                      return status.termsAndConditions;
                                    })),
                              projectStatus: status
                            })
                      ]
                    }),
                JsxRuntime.jsx(ProjectWizardNavigation.make, {
                      stepLabel: "Step " + x + " of " + x,
                      errorMessage: state.errorMessage,
                      controls: {
                        TAG: "PreviousAndNextAndContinueLater",
                        next: navigationNextControl,
                        previous: navigationPreviousControl,
                        continueLater: navigationContinueLaterControl
                      },
                      mobile: props.mobile,
                      convertButtonClass: convertButtonClass
                    }),
                JsxRuntime.jsx(ReactNotifications.NotificationContainer, {})
              ]
            });
}

var make = ProjectWizardGeneralInformation;

export {
  css ,
  updateFieldInContact ,
  Api ,
  make ,
}
/* css Not a pure module */
