// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as Select from "../../../../../styleguide/forms/Select/Select.res.js";
import * as Checkbox from "../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardGeneralInformationScss from "./ProjectWizardGeneralInformation.scss";
import * as ProjectWizardGeneralInformation_FieldValidationMessage from "./ProjectWizardGeneralInformation_FieldValidationMessage.res.js";

var css = ProjectWizardGeneralInformationScss;

function ProjectWizardGeneralInformation_FlagsSection$AgencySelector(props) {
  var setAgency = props.setAgency;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: "Partnering with the following agency",
                      className: css.agencySelectionHeading
                    }),
                JsxRuntime.jsx(Select.make, {
                      id: "colocation-rfp-general-info-agency-selection",
                      value: props.selectedAgency,
                      disabled: !props.canAgencyBeManaged,
                      className: css.agencySelector,
                      wrapperClassName: css.radiusFilterWrapper,
                      iconSize: "XS",
                      iconColor: "Gray",
                      onChange: (function ($$event) {
                          setAgency($$event.target.value);
                        }),
                      children: Belt_Array.map(props.agencyOptions, (function (agency) {
                              return JsxRuntime.jsx(Select.$$Option.make, {
                                          value: agency,
                                          children: agency
                                        }, agency);
                            }))
                    })
              ],
              className: css.agencySelectionContainer
            });
}

var AgencySelector = {
  make: ProjectWizardGeneralInformation_FlagsSection$AgencySelector
};

function ProjectWizardGeneralInformation_FlagsSection(props) {
  var agencyOptions = props.agencyOptions;
  var userRole = props.userRole;
  var updateFlag = props.updateFlag;
  var acceptedTermsAndConditions = props.acceptedTermsAndConditions;
  var isAgent = props.isAgent;
  var tmp;
  tmp = userRole === "Agent" ? true : false;
  var tmp$1;
  var exit = 0;
  var exit$1 = 0;
  switch (userRole) {
    case "Admin" :
    case "Concierge" :
    case "Agent" :
        exit$1 = 2;
        break;
    default:
      exit = 1;
  }
  if (exit$1 === 2) {
    if (agencyOptions.length !== 0) {
      tmp$1 = JsxRuntime.jsx(ProjectWizardGeneralInformation_FlagsSection$AgencySelector, {
            selectedAgency: props.selectedAgency,
            setAgency: props.setAgency,
            agencyOptions: agencyOptions,
            canAgencyBeManaged: props.canAgencyBeManaged
          });
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    if (isAgent) {
      switch (userRole) {
        case "Provider" :
        case "User" :
        case "Visitor" :
            tmp$1 = null;
            break;
        default:
          tmp$1 = null;
      }
    } else {
      tmp$1 = null;
    }
  }
  var tmp$2;
  tmp$2 = props.projectId !== undefined ? (
      props.projectStatus === "Draft" ? JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Checkbox.make, {
                      id: "project-general-info-terms-and-conditions",
                      size: "LG",
                      checked: acceptedTermsAndConditions,
                      onChange: (function ($$event) {
                          updateFlag("TermsAndConditions", $$event.target.checked);
                        })
                    }),
                JsxRuntime.jsxs(Label.make, {
                      forId: "project-general-info-terms-and-conditions",
                      children: [
                        "I agree to the ",
                        JsxRuntime.jsx("a", {
                              children: "Terms & Conditions",
                              href: "/terms-of-use",
                              target: "_blank"
                            })
                      ]
                    })
              ],
              className: css.primaryCheckBoxContainer
            }) : null
    ) : JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx(Checkbox.make, {
                  id: "project-general-info-terms-and-conditions",
                  size: "LG",
                  checked: acceptedTermsAndConditions,
                  onChange: (function ($$event) {
                      updateFlag("TermsAndConditions", $$event.target.checked);
                    })
                }),
            JsxRuntime.jsxs(Label.make, {
                  forId: "project-general-info-terms-and-conditions",
                  children: [
                    "I agree to the ",
                    JsxRuntime.jsx("a", {
                          children: "Terms & Conditions",
                          href: "/terms-of-use",
                          target: "_blank"
                        })
                  ]
                })
          ],
          className: css.primaryCheckBoxContainer
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Checkbox.make, {
                              id: "project-general-info-isAgent",
                              size: "LG",
                              checked: isAgent,
                              visuallyDisabled: tmp,
                              onChange: (function ($$event) {
                                  updateFlag("Agent", $$event.target.checked);
                                })
                            }),
                        JsxRuntime.jsx(Label.make, {
                              forId: "project-general-info-isAgent",
                              children: "I am an agent working on behalf of a client"
                            })
                      ],
                      className: css.primaryCheckBoxContainer
                    }),
                tmp$1,
                tmp$2,
                JsxRuntime.jsx(ProjectWizardGeneralInformation_FieldValidationMessage.make, {
                      status: props.termsAndConditionsValidationStatus
                    })
              ]
            });
}

var make = ProjectWizardGeneralInformation_FlagsSection;

export {
  css ,
  AgencySelector ,
  make ,
}
/* css Not a pure module */
