// extracted by mini-css-extract-plugin
export var column = "UserColocationRfpProjectWizardTooltip__column__BUG2w";
export var icon = "UserColocationRfpProjectWizardTooltip__icon__xZmWl";
export var iconCenterLeftTooltip = "UserColocationRfpProjectWizardTooltip__iconCenterLeftTooltip__TA3v2";
export var iconCenterRightTooltip = "UserColocationRfpProjectWizardTooltip__iconCenterRightTooltip__ZMWvB";
export var iconFineRightTooltip = "UserColocationRfpProjectWizardTooltip__iconFineRightTooltip__r7IEQ";
export var iconLeftTooltip = "UserColocationRfpProjectWizardTooltip__iconLeftTooltip__GcLIO";
export var iconRightTooltip = "UserColocationRfpProjectWizardTooltip__iconRightTooltip__DOoVJ";
export var info = "UserColocationRfpProjectWizardTooltip__info__fQ1gd";
export var row = "UserColocationRfpProjectWizardTooltip__row__rDDaN";
export var tooltipPositionContainer = "UserColocationRfpProjectWizardTooltip__tooltipPositionContainer__QwyVH";